import { Injectable } from '@angular/core';
import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';
import { Space } from '@memberspot/shared/model/community';

export interface SpacesState
  extends EntityState<Space, string>,
    ActiveState<string> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'spaces' })
export class SpacesStore extends EntityStore<SpacesState> {
  constructor() {
    super();
  }
}
